import FeatherIcon from "feather-icons-react";
import React from "react";
import Swal from "sweetalert2";
import CreateVraiFaux from "../CreateVraiFaux";
import { hideModal } from "../ItemSessionModule";
import { DeleteQuestionButton, useDeleteQuiz } from "./SupportQcm";
function SupportContentYN({ data, contentId, onReload }) {
  const dq = useDeleteQuiz(onReload);
  const DATA = [
    { title: "Prétest", value: data.is_pretest ? "OUI" : "NON" },
    { title: "Test final", value: data.is_testfinal ? "OUI" : "NON" },
  ];
  return (
    <div>
      <div className="row mt-1 mb-2">
        <div className="col-md-10">
          <div
            className="link-collapse-session-item"
            style={{ position: "relative" }}
          >
            {data.title}
          </div>
        </div>
        <div className="col-md-2 text-right">
          {/* <button
            onClick={() => dq(data.id)}
            className="btn-action-modul-session"
          >
            <FeatherIcon icon="trash-2" />
          </button> */}
        </div>
        {DATA.map((row) => {
          return (
            <div className="col-md-4 text-center mb-2" key={row.title}>
              <div>{row.title}</div>
              <div style={{ fontWeight: "bold" }}>{row.value}</div>
            </div>
          );
        })}
      </div>
      {data.list_question.map((q) => {
        return (
          <YesNoItem
            contentId={contentId}
            onReload={onReload}
            quiz={data.id}
            item={q}
            key={q.id}
          />
        );
      })}
      <AddButton
        content={contentId}
        onReload={onReload}
        quiz={data.id}
        title="Ajouter une question"
      />
    </div>
  );
}

export default SupportContentYN;

function AddButton({ content, question, quiz, onReload, title }) {
  const modalId = `ModalAddYesNo${question ? question : ""}${quiz}`;
  return (
    <>
      <div className="mt-2">
        <button
          data-toggle="modal"
          data-target={`#${modalId}`}
          className="btn-light-add-content m-b-0"
        >
          <FeatherIcon className="icon-btn-add-dashboard" icon="plus" />
          {title}
        </button>
      </div>
      <div
        className="modal fade"
        id={modalId}
        data-backdrop="static"
        data-keyboard="false"
        tabIndex="-1"
        aria-labelledby="ModalCreateQcmLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header b-b-0">
              <h6
                className="modal-title fw-500 text-black"
                id="ModalCreateQcmLabel"
              >
                Créer un contenu
              </h6>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <CreateVraiFaux
              notify={() => {
                onReload();
                hideModal(`#${modalId}`);
                Swal.fire({
                  icon: "success",
                  title: "Données ajoutées avec succès",
                  iconColor: "green",
                  showConfirmButton: false,
                  timer: 1500,
                });
              }}
              contentId={content}
              question={question}
              quiz={quiz}
            />
          </div>
        </div>
      </div>
    </>
  );
}

function YesNoItem({ item, contentId, quiz, onReload }) {
  const collapseId = `YesNoItem${item.id}`;
  const DATA = [{ title: "Score", value: item.score }];
  return (
    <div className="session-content-container">
      <div className="card-item-sessions-module contenu-shadow">
        <a
          className="link-collapse-session-item collapsed"
          data-toggle="collapse"
          href={`#${collapseId}`}
          role="button"
          style={{ marginRight: 50 }}
          aria-expanded="false"
          aria-controls="collapseItemSessionModule"
        >
          <i
            className="fa chevron-collapse-session-item"
            aria-expanded="false"
          ></i>
          {item.content}
        </a>
        <div className="collapse" id={collapseId}>
          <div className="card card-body p-t-20 m-b-0">
            <div
              className="row dis-flex justify-content-center"
              style={{ width: "100%" }}
            >
              <div className="col-md-10 offset-md-1">
                <div className="session-content-container">
                  <div className="row">
                    {DATA.map((row) => {
                      return (
                        <div
                          className="col-md-4 text-center mb-2"
                          key={row.title}
                        >
                          <div>{row.title}</div>
                          <div style={{ fontWeight: "bold" }}>{row.value}</div>
                        </div>
                      );
                    })}
                  </div>

                  <ul className="list-group">
                    {item.list_responses.map((r) => {
                      const className = r.is_right_choice
                        ? "list-group-item text-success"
                        : "list-group-item";
                      return (
                        <li key={r.id} className={className}>
                          <span
                            style={{
                              color: r.is_right_choice ? "#0F0" : "#F00",
                            }}
                          >
                            {r.content}
                          </span>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <DeleteQuestionButton onReload={onReload} questionId={item.id} /> */}
      </div>
    </div>
  );
}
