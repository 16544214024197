import FeatherIcon from "feather-icons-react";
import React from "react";
import Swal from "sweetalert2";
import CreateTexteATrous from "../CreateTexteATrous";
import { hideModal } from "../ItemSessionModule";
import { useDeleteTextTrous, useDeleteWord } from "./SupportQcm";

function SupportContentTextATroux({ data, onReload, contentId }) {
  // const dq = useDeleteTextTrous(onReload);
  return (
    <div>
      <div className="row mt-1 mb-2">
        <div className="col-md-10">
          <div
            className="link-collapse-session-item"
            style={{ position: "relative" }}
          >
            {data.name}
          </div>
        </div>
        <div className="col-md-2 text-right">
          {/* <button
            onClick={() => dq(data.id)}
            className="btn-action-modul-session"
          >
            <FeatherIcon icon="trash-2" />
          </button> */}
          <UpdateButton
            complete_content={data.complete_content}
            name={data.name}
            content={contentId}
            onReload={onReload}
            taskId={data.id}
          />
        </div>
      </div>
      <ComplateText taskId={data.id} text={data.complete_content} />
      <WordItems
        contentId={contentId}
        onReload={onReload}
        toDelete={true}
        title="Mot à enlever"
        taskId={data.id}
        data={data.list_mots_enlever}
      />
      <WordItems
        contentId={contentId}
        onReload={onReload}
        toDelete={false}
        title="Mot complémentaire"
        taskId={data.id}
        data={data.list_mots_supplementaires}
      />
      <AddButton
        content={contentId}
        onReload={onReload}
        taskId={data.id}
        title="Ajouter un mot"
      />
    </div>
  );
}

export default SupportContentTextATroux;

function ComplateText({ taskId, text }) {
  const collapseId = `ComplateText${taskId}`;
  return (
    <div className="session-content-container">
      <div className="card-item-sessions-module contenu-shadow">
        <a
          className="link-collapse-session-item collapsed"
          data-toggle="collapse"
          href={`#${collapseId}`}
          role="button"
          style={{ marginRight: 50 }}
          aria-expanded="false"
          aria-controls="collapseItemSessionModule"
        >
          <i
            className="fa chevron-collapse-session-item"
            aria-expanded="false"
          ></i>
          Texte intégral
        </a>
        <div className="collapse" id={collapseId}>
          <div className="card card-body p-t-20 m-b-0">
            <div
              className="row dis-flex justify-content-center"
              style={{ width: "100%" }}
            >
              <div className="col-md-10 offset-md-1">
                <div
                  className="session-content-container"
                  dangerouslySetInnerHTML={{ __html: text }}
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function WordItems({ taskId, data, onReload, title, toDelete }) {
  const collapseId = `WordToRemoveItem_${
    toDelete ? "Delete" : "NoDelete"
  }_${taskId}`;

  return (
    <div className="session-content-container">
      <div className="card-item-sessions-module contenu-shadow">
        <a
          className="link-collapse-session-item collapsed"
          data-toggle="collapse"
          href={`#${collapseId}`}
          role="button"
          style={{ marginRight: 50 }}
          aria-expanded="false"
          aria-controls="collapseItemSessionModule"
        >
          <i
            className="fa chevron-collapse-session-item"
            aria-expanded="false"
          ></i>
          {title}
        </a>
        <div className="collapse" id={collapseId}>
          <div className="card card-body p-t-20 m-b-0">
            <div
              className="row dis-flex justify-content-center"
              style={{ width: "100%" }}
            >
              <div className="col-md-10 offset-md-1">
                <div className="session-content-container">
                  <ListWord onReload={onReload} data={data} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function ListWord({ data, onReload }) {
  const deleteWord = useDeleteWord(onReload);
  return (
    <ul className="list-group">
      {data.map((r) => {
        return (
          <li key={r.id} className={"list-group-item"}>
            <span>{r.content}</span>
            {/* <div
              style={{ top: 0, right: 0 }}
              className="btn-action-container-modul-session"
            >
              <button
                onClick={() => deleteWord(r.id)}
                className="btn-action-modul-session"
              >
                <FeatherIcon icon="trash-2" />
              </button>
            </div> */}
          </li>
        );
      })}
    </ul>
  );
}

function AddButton({ content, taskId, onReload, title }) {
  const modalId = `ModalAddTextTrous${taskId}`;
  return (
    <>
      <div className="mt-2">
        <button
          data-toggle="modal"
          data-target={`#${modalId}`}
          className="btn-light-add-content m-b-0"
        >
          <FeatherIcon className="icon-btn-add-dashboard" icon="plus" />
          {title}
        </button>
      </div>
      <div
        className="modal fade"
        id={modalId}
        data-backdrop="static"
        data-keyboard="false"
        tabIndex="-1"
        aria-labelledby="ModalCreateQcmLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header b-b-0">
              <h6
                className="modal-title fw-500 text-black"
                id="ModalCreateQcmLabel"
              >
                Créer un contenu
              </h6>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <CreateTexteATrous
              notify={() => {
                onReload();
                hideModal(`#${modalId}`);
                Swal.fire({
                  icon: "success",
                  title: "Données ajoutées avec succès",
                  iconColor: "green",
                  showConfirmButton: false,
                  timer: 1500,
                });
              }}
              contentId={content}
              taskId={taskId}
            />
          </div>
        </div>
      </div>
    </>
  );
}

function UpdateButton({ complete_content, content, name, taskId, onReload }) {
  const modalId = `UpdateButton${taskId}`;
  return (
    <>
      <div className="mt-2">
        <button
          data-toggle="modal"
          data-target={`#${modalId}`}
          className="btn-action-modul-session"
        >
          <FeatherIcon icon="edit" />
        </button>
      </div>
      <div
        className="modal fade"
        id={modalId}
        data-backdrop="static"
        data-keyboard="false"
        tabIndex="-1"
        aria-labelledby="ModalCreateQcmLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header b-b-0">
              <h6
                className="modal-title fw-500 text-black"
                id="ModalCreateQcmLabel"
              >
                Modifier un contenu
              </h6>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <CreateTexteATrous
              notify={() => {
                onReload();
                hideModal(`#${modalId}`);
                Swal.fire({
                  icon: "success",
                  title: "Données ajoutées avec succès",
                  iconColor: "green",
                  showConfirmButton: false,
                  timer: 1500,
                });
              }}
              contentId={content}
              taskId={taskId}
              update
              content={complete_content}
              name={name}
            />
          </div>
        </div>
      </div>
    </>
  );
}
