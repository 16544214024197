import React from "react";
import ReactAudioPlayer from "react-audio-player";
import FeatherIcon from 'feather-icons-react'
import Swal from 'sweetalert2'
import api from '../../../http/global-vars'
import axios from 'axios'
import EditUpload from '../EditUpload'





function SupportUpload({ data , onReload}) {

  const onDelete = () => {
    Swal.fire({
      title: 'Êtes-vous sûr de vouloir supprimer ce contenu ?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Oui',
      cancelButtonText: 'Non',
      showLoaderOnConfirm: true,
      focusCancel: false,
      iconColor: '#f39200',
      preConfirm: () => {
        return axios
          .delete(`${api}quizfree/${data.id}`, {
            headers: { Authorization: `Bearer ${localStorage.userToken}` },
          })
          .then(async (response) => {
            // await getContent()
            return response
          })
          .catch((error) => {
            Swal.showValidationMessage(`Erreur: ${error}`)
          })
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((results) => {
      if (results?.isConfirmed) {
        Swal.fire({
          title: 'Contenu supprimé avec succès !',
          icon: 'success',
          showCancelButton: false,
          showConfirmButton: false,
          focusCancel: false,
          iconColor: '#f39200',
          timer: 3000,
        })
        onReload()
      }
    })
  }
  
  return (
    <div className="row">
      
      <div className="col-md-8"> {data?.title}</div>
      <div className="col-md-4">
        <button
          className="btn-action-modul-session"
          data-toggle="modal"
          data-target={`#EditUpload${data?.id}`}
        >
          <FeatherIcon icon="edit" />
        </button>
        {/* <button
          className="btn-action-modul-session"
          title="Supprimer"
           onClick={() => onDelete()}
        >
          <FeatherIcon icon="trash-2" />
        </button> */}
      </div>
      <div className="col-md-12 m-t-6"> <h5>Questions</h5></div>
      <div className="col-md-12"> 
        <div className="row m-t-10">
          {data?.list_question?.map((c) => (
            <React.Fragment key={c?.id}>
              <div className="col-md-6 "> 
                <ul className="session-content-reponse" key={c?.id}>
                  <h6>{c?.content} ?</h6>
                </ul>
              </div>
            </React.Fragment>
          ))}
        </div> 
      </div> 
      <div className="btn-action-container-modul-session">
         
        <div
          className="modal fade"
          id={`EditUpload${data?.id}`}
          tabIndex="-1"
          role="dialog"
          aria-labelledby="myLargeModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-xl">
            <div className="modal-content card-dashboard">
              <EditUpload onReload={onReload} data={data}/>
            </div>
          </div>
        </div>
    
      </div>
    </div>
  );
}

export default SupportUpload;

