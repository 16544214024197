import axios from 'axios'
import React, { useCallback } from 'react'
import Swal from 'sweetalert2'
import FeatherIcon from 'feather-icons-react'
import api from '../../../http/global-vars'
import './SupportContent.css'

const SupportContentTextField = ({ data, onReload }) => {
  const onDelete = useCallback(() => {
    Swal.fire({
      title: 'Êtes-vous sûr de vouloir supprimer cet item ?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Oui',
      cancelButtonText: 'Non',
      showLoaderOnConfirm: true,
      focusCancel: false,
      iconColor: '#f39200',
      preConfirm: () => {
        return axios
          .delete(`${api}quizfree/${data?.id}`, {
            headers: { Authorization: `Bearer ${localStorage.userToken}` },
          })
          .then(async (response) => {
            await onReload()
            return response
          })
          .catch((error) => {
            Swal.showValidationMessage(`Erreur: ${error}`)
          })
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((results) => {
      if (results?.isConfirmed) {
        Swal.fire({
          title: 'Item supprimé avec succès !',
          icon: 'success',
          showCancelButton: false,
          showConfirmButton: false,
          focusCancel: false,
          iconColor: '#f39200',
          timer: 3000,
        })
      }
    })
  }, [onReload, data])

  const onDeleteQuestion = useCallback(
    (id) => {
      Swal.fire({
        title: 'Êtes-vous sûr de vouloir supprimer cette question ?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Oui',
        cancelButtonText: 'Non',
        showLoaderOnConfirm: true,
        focusCancel: false,
        iconColor: '#f39200',
        preConfirm: () => {
          return axios
            .delete(`${api}questionfree/${id}`, {
              headers: { Authorization: `Bearer ${localStorage.userToken}` },
            })
            .then(async (response) => {
              await onReload()
              return response
            })
            .catch((error) => {
              Swal.showValidationMessage(`Erreur: ${error}`)
            })
        },
        allowOutsideClick: () => !Swal.isLoading(),
      }).then((results) => {
        if (results?.isConfirmed) {
          Swal.fire({
            title: 'Question supprimée avec succès !',
            icon: 'success',
            showCancelButton: false,
            showConfirmButton: false,
            focusCancel: false,
            iconColor: '#f39200',
            timer: 3000,
          })
        }
      })
    },
    [onReload],
  )

  const onDeleteResponse = useCallback(
    (id) => {
      Swal.fire({
        title: 'Êtes-vous sûr de vouloir supprimer ce champs de réponse ?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Oui',
        cancelButtonText: 'Non',
        showLoaderOnConfirm: true,
        focusCancel: false,
        iconColor: '#f39200',
        preConfirm: () => {
          return axios
            .delete(`${api}responsequestionfree/${id}`, {
              headers: { Authorization: `Bearer ${localStorage.userToken}` },
            })
            .then(async (response) => {
              await onReload()
              return response
            })
            .catch((error) => {
              Swal.showValidationMessage(`Erreur: ${error}`)
            })
        },
        allowOutsideClick: () => !Swal.isLoading(),
      }).then((results) => {
        if (results?.isConfirmed) {
          Swal.fire({
            title: 'Champs supprimé avec succès !',
            icon: 'success',
            showCancelButton: false,
            showConfirmButton: false,
            focusCancel: false,
            iconColor: '#f39200',
            timer: 3000,
          })
        }
      })
    },
    [onReload],
  )
  return (
    <div className="text-field-component">
      <div className="d-flex justify-content-between align-items-baseline">
        <p className="m-b-35 fw-500 f-f-work text-dark fs-20">
          Consigne : {data?.title}{' '}
        </p>{' '}
        {/* <button
          className="btn-action-modul-session"
          title="Supprimer"
          onClick={() => onDelete()}
        >
          <FeatherIcon icon="trash-2" />
        </button> */}
      </div>
      <h6 className="m-b-20 fw-400 f-f-work text-dark fs-15">Questions</h6>
      <ul>
        {data?.list_question?.map((question) => (
          <ol key={question?.id} className="px-1 mb-3">
            <li
              className="link-collapse-session-item collapsed h-theme-light justify-content-between"
              role="button"
              data-toggle="collapse"
              data-target={`#questionCollapse${question?.id}`}
              aria-expanded="false"
              aria-controls="questionCollapse"
            >
				<div className="d-flex align-items-center">
			<i
                className="fa chevron-collapse-session-item"
                aria-expanded="false"
              ></i>
              <span>{question?.content}</span>
				</div>
              
              {/* <button
                className="btn-action-modul-session"
                title="Supprimer"
                onClick={() => onDeleteQuestion(question?.id)}
              >
                <FeatherIcon icon="trash-2" />
              </button> */}
            </li>
            <div class="collapse" id={`questionCollapse${question?.id}`}>
              <h6>Réponses</h6>
              <ol class="list-reponses">
                {question?.list_responses?.map((answer,i) => (
                  <li key={answer?.id} className="answer-text mb-2 justify-content-between d-flex align-items-center">
                    <span>{answer?.content || `Champs ${i + 1}`}</span>
                    {/* <button
                      className="btn-action-modul-session"
                      title="Supprimer"
                      onClick={() => onDeleteResponse(answer?.id)}
                    >
                      <FeatherIcon icon="trash-2" />
                    </button> */}
                  </li>
                ))}
              </ol>
            </div>
          </ol>
        ))}
      </ul>
    </div>
  )
}

export default SupportContentTextField
