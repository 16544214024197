import React from 'react'
import './SupportContent.css'
import FeatherIcon from 'feather-icons-react'
import ReactPlayer from 'react-player'
import { DeleteSupport } from '../ItemIntroModule'
import EditSlideModal from '../EditSlideModal'
import EditVideoModal from '../EditVideoModal'

const SupportContentVideo = ({ data, onReload }) => {
  return (
    <div class="d-flex justify-content-between align-items-start">
      <div className="video-component w-100">
        <div key={data?.id}>
          <p className="titre-lecon-start-page">{data.name}</p>
          <div className="content-video-viemo">
            <ReactPlayer
              config={{
                vimeo: {
                  playerOptions: {
                    title: false,
                    byline: false,
                    portrait: false,
                  },
                },
              }}
              url={`${data.url_vimeo}?`}
              controls={true}
              className="video-viemo"
              playing={false}
              width="100%"
              height="100%"
            />
          </div>

          <div className="lh-3">
            <FeatherIcon className="icon-collapse-video" icon="align-left" />
            <a
              data-toggle="collapse"
              href="#collapseExample"
              id="collapseExample1"
              className="video-collapse-video"
            >
              VOIR LA DESCRIPTION
            </a>
          </div>
          <div className="collapse" id="collapseExample">
            <div className="video-black">
              <div dangerouslySetInnerHTML={{ __html: data?.description }} />
            </div>
          </div>
        </div>
      </div>
	  <div className="d-flex">
        <EditBtnSupport video={data} onReload={() => onReload()} />
        {/* <DeleteSupport id={data?.id} onReload={() => onReload()} /> */}
      </div>
    </div>
  )
}

export default SupportContentVideo

const EditBtnSupport = ({ video, onReload }) => {
  return (
    <>
      <button
        className="btn-action-modul-session"
        data-toggle="modal"
        data-target={`#editVideoModal${video?.id}`}
      >
        <FeatherIcon icon="edit" />
      </button>

      <div
        className="modal fade "
        id={`editVideoModal${video?.id}`}
        tabIndex="-1"
        role="dialog"
        aria-labelledby="myLargeModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content card-dashboard">
            <EditVideoModal video={video} onReload={() => onReload()} />
          </div>
        </div>
      </div>
    </>
  )
}