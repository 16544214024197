import FeatherIcon from "feather-icons-react";
import React from "react";
import Swal from "sweetalert2";
import baseUrl from "../../../http/backend-base-url";
import CreateImages from "../CreateImages";
import { hideModal } from "../ItemSessionModule";
import { useDeleteQuiz, useDeleteResponse } from "./SupportQcm";
function SupportImages({ data, contentId, onReload }) {
  const modalId = `ModalAddImage${data.id}`;
  const dq = useDeleteQuiz(onReload);
  const DATA = [
    { title: "Prétest", value: data.is_pretest ? "OUI" : "NON" },
    { title: "Test final", value: data.is_testfinal ? "OUI" : "NON" },
  ];
  return (
    <div>
      <div className="row mt-1 mb-2">
        <div className="col-md-12">
          <div
            className="link-collapse-session-item"
            style={{ position: "relative" }}
          >
            {data.title}
          </div>
        </div>
        {/* <div className="col-md-6 text-right">
          <button
            onClick={() => dq(data.id)}
            className="btn-action-modul-session"
          >
            <FeatherIcon icon="trash-2" />
          </button>
        </div> */}
        {DATA.map((row) => {
          return (
            <div className="col-md-4 text-center mb-2" key={row.title}>
              <div>{row.title}</div>
              <div style={{ fontWeight: "bold" }}>{row.value}</div>
            </div>
          );
        })}
      </div>

      {data.list_question.map((q) => {
        return <QcmItem onReload={onReload} item={q} key={q.id} />;
      })}
      <div className="mt-2">
        <button
          data-toggle="modal"
          data-target={`#${modalId}`}
          className="btn-light-add-content m-b-0"
        >
          <FeatherIcon className="icon-btn-add-dashboard" icon="plus" />
          Ajouter une image
        </button>
      </div>
      <div
        className="modal fade"
        id={modalId}
        data-backdrop="static"
        data-keyboard="false"
        tabIndex="-1"
        aria-labelledby="ModalCreateQcmLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header b-b-0">
              <h6
                className="modal-title fw-500 text-black"
                id="ModalCreateQcmLabel"
              >
                Créer un contenu
              </h6>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <CreateImages
              notify={() => {
                onReload();
                hideModal(`#${modalId}`);
                Swal.fire({
                  icon: "success",
                  title: "Données ajoutées avec succès",
                  iconColor: "green",
                  showConfirmButton: false,
                  timer: 1500,
                });
              }}
              contentId={contentId}
              quiz={data.id}
              question={data.list_question[0]?.id}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default SupportImages;

function QcmItem({ item, onReload }) {
  const onDelete = useDeleteResponse(onReload);
  return (
    <div className="session-content-container">
      <div className="card-item-sessions-module contenu-shadow">
        <div className="link-collapse-session-item collapsed">
          <i
            className="fa chevron-collapse-session-item"
            aria-expanded="false"
          ></i>
          {item.content}
        </div>
        <div className="card card-body p-t-20 m-b-0">
          <div
            className="row dis-flex justify-content-center"
            style={{ width: "100%" }}
          >
            <div className="col-md-4 text-center mb-2">
              <div>Score</div>
              <div style={{ fontWeight: "bold" }}>{item.score}</div>
            </div>
            <div className="col-md-10 offset-md-1">
              <div className="row">
                {item.list_responses.map((r) => {
                  return (
                    <div key={r.id} className="col-md-4 mb-2">
                      <div
                        style={{
                          maxWidth: 150,
                          maxHeight: 150,
                          minHeight: 100,
                          borderWidth: 2,
                          borderColor: r.is_right_choice ? "#0F0" : "#F00",
                          borderStyle: "solid",
                          position: "relative",
                        }}
                      >
                        <img
                          alt={r.content}
                          style={{
                            width: "100%",
                            height: "100%",
                            maxWidth: 150,
                            maxHeight: 150,
                            minHeight: 100,
                          }}
                          src={baseUrl + r.image}
                        />
                        {/* <div
                          style={{ top: 5, right: 5 }}
                          className="btn-action-container-modul-session"
                        >
                          <button
                            onClick={() => onDelete(r.id)}
                            className="btn-action-modul-session"
                          >
                            <FeatherIcon icon="trash-2" />
                          </button>
                        </div> */}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
