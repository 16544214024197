import Axios from "axios";
import React, { useState, useEffect } from "react";
import { useLocation, useParams, useHistory } from "react-router-dom";
import api from "../../http/global-vars";
import SliderContentDetailsSession from "./SliderContentDetailsSession";
import SliderScormContainer from "./SliderScormContainer";
import Skeleton from "react-loading-skeleton";
import NavbarVertical from "../components/NavbarVertical";
import HeaderDashboard from "../components/HeaderDashboard";
import NavbarDashboardMobile from "../components/NavbarDashboardMobile";

function SliderContentContainer() {
  const config = {
    headers: {
      Authorization: `Bearer ${window.localStorage.getItem("userToken")}`,
    },
  };
  const [isLoading, setIsLoading] = useState(true);
  const { state } = useLocation();
  const [item, setItem] = useState(state);
  const [theme, setTheme] = useState(state);
  const history = useHistory();
  const { id, component } = useParams();

  useEffect(() => {
    window.scroll(0, 0);
    if (id) {
      if (component === "module") {
        getModule(id);
      } else {
        setIsLoading(false)
      }
      
    } else {
      history.goBack();
    }
  }, [id,component]);

  useEffect(() => {
    if (state) {
      setItem(state);
      setIsLoading(false);
    }
  }, [state]);

  const getModule = async (id) => {
    await Axios.get(`${api}module/${id}`, config)
      .then(async (res) => {
        await setItem(res?.data);
      })
      .catch((err) => {
        console.log("err", err);
        setIsLoading(false);
        history.goBack();
      });
  };

  useEffect(() => {
    if (item?.thematique) {
      getTheme(item?.thematique);
    }
  }, [item]);

  const getTheme = async (id) => {
    await Axios.get(`${api}module/${id}`, config)
      .then(async (res) => {
        await setTheme(res?.data);
        await setIsLoading(false);
      })
      .catch((err) => {
        console.log("err", err);
        setIsLoading(false);
        history.goBack();
      });
  };

  return (
    <>
      {isLoading ? (
        <SliderLoader />
      ) : item?.scorm_link ? (
        <SliderScormContainer module={item} theme={theme} />
      ) : (
        <SliderContentDetailsSession />
      )}
    </>
  );
}

export default SliderContentContainer;
const SliderLoader = () => {
  return (
    <div className="container-fluid body-background-dashboard">
      <div className="row">
        {/* Navbar Mobile */}
        <div className="col-xl-2 col-lg-2 col-md-12 no-padding no-view-desktop">
          <NavbarDashboardMobile />
        </div>
      </div>

      <HeaderDashboard />

      <div className="row">
        {/* Navbar Desktop  */}
        <div className="col-xl-2 col-lg-3 col-md-12">
          <div className="navbar-vertical-customisation no-view-mobile">
            <NavbarVertical />
          </div>
        </div>

        <div className="col-xl-10 col-lg-9 col-md-12 content-position-after-header p-b-200">
          <div className="test">
            <div className="row">
              <div className="col-xl-12">
                <div className="content-slider-details-session">
                  <div className="body-content-slider-details-sessions">
                    <Skeleton height={`100vh`} width={`100%`} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
