import React, { useCallback } from 'react'
import FeatherIcon from 'feather-icons-react'
import Swal from 'sweetalert2'
import axios from 'axios'
import api from '../../../http/global-vars'

function SupportGlisseDepot({ data, onReload }) {
 
  const onDelete = useCallback(
    () => {
      Swal.fire({
        title: 'Êtes-vous sûr de vouloir supprimer cet item ?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Oui',
        cancelButtonText: 'Non',
        showLoaderOnConfirm: true,
        focusCancel: false,
        iconColor: '#f39200',
        preConfirm: () => {
          return axios
            .delete(`${api}dragdroptask/${data?.id}`, {
              headers: { Authorization: `Bearer ${localStorage.userToken}` },
            })
            .then(async (response) => {
              await onReload()
              return response
            })
            .catch((error) => {
              Swal.showValidationMessage(`Erreur: ${error}`)
            })
        },
        allowOutsideClick: () => !Swal.isLoading(),
      }).then((results) => {
        if (results?.isConfirmed) {
          Swal.fire({
            title: 'Item supprimé avec succès !',
            icon: 'success',
            showCancelButton: false,
            showConfirmButton: false,
            focusCancel: false,
            iconColor: '#f39200',
            timer: 3000,
          })
        }
      })
    },
    [onReload,data],
  )

  const onDeleteColumn = useCallback(
    (id) => {
      Swal.fire({
        title: 'Êtes-vous sûr de vouloir supprimer cette colonne ?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Oui',
        cancelButtonText: 'Non',
        showLoaderOnConfirm: true,
        focusCancel: false,
        iconColor: '#f39200',
        preConfirm: () => {
          return axios
            .delete(`${api}colonnedrag/${id}`, {
              headers: { Authorization: `Bearer ${localStorage.userToken}` },
            })
            .then(async (response) => {
              await onReload()
              return response
            })
            .catch((error) => {
              Swal.showValidationMessage(`Erreur: ${error}`)
            })
        },
        allowOutsideClick: () => !Swal.isLoading(),
      }).then((results) => {
        if (results?.isConfirmed) {
          Swal.fire({
            title: 'Colonne supprimée avec succès !',
            icon: 'success',
            showCancelButton: false,
            showConfirmButton: false,
            focusCancel: false,
            iconColor: '#f39200',
            timer: 3000,
          })
        }
      })
    },
    [onReload],
  )

  const onDeleteWord = useCallback(
    (id) => {
      Swal.fire({
        title: 'Êtes-vous sûr de vouloir supprimer ce mot ?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Oui',
        cancelButtonText: 'Non',
        showLoaderOnConfirm: true,
        focusCancel: false,
        iconColor: '#f39200',
        preConfirm: () => {
          return axios
            .delete(`${api}wordcolonne/${id}`, {
              headers: { Authorization: `Bearer ${localStorage.userToken}` },
            })
            .then(async (response) => {
              await onReload()
              return response
            })
            .catch((error) => {
              Swal.showValidationMessage(`Erreur: ${error}`)
            })
        },
        allowOutsideClick: () => !Swal.isLoading(),
      }).then((results) => {
        if (results?.isConfirmed) {
          Swal.fire({
            title: 'Mot supprimé avec succès !',
            icon: 'success',
            showCancelButton: false,
            showConfirmButton: false,
            focusCancel: false,
            iconColor: '#f39200',
            timer: 3000,
          })
        }
      })
    },
    [onReload],
  )
  return (
    <div className="row">
      <div className="col-md-12 d-flex justify-content-between align-items-baseline">
        <p className="m-b-35 fw-500 f-f-work text-dark fs-20">
          Consigne : {data?.name}{' '}
        </p>{' '}
        {/* <button className="btn-action-modul-session" title="Supprimer" onClick={() => onDelete()}>
          <FeatherIcon icon="trash-2" />
        </button> */}
      </div>
      <div className="col-md-12">
        <div className="row m-t-5">
          {data?.list_colonne?.map((c, i) => (
            <React.Fragment key={c?.id}>
              <div className="col-md-6">
                <ul className="session-content-reponse" key={c?.id}>
                  <div className={i % 2 ? 'head-term-right' : 'head-term-left'}>
                    {' '}
                    <span>{c?.name}</span>{' '}
                    {/* <button
                      className="btn-action-modul-session"
                      title="Supprimer"
                      onClick={() => onDeleteColumn(c?.id)}
                    >
                      <FeatherIcon icon="trash-2" style={{ color: '#FFF' }} />
                    </button> */}
                  </div>
                  {c?.list_words?.map((w) => (
                    <div
                      className={
                        i % 2 ? 'item-dnd-admin-right' : 'item-dnd-admin-left'
                      }
                      key={w?.id}
                    >
                      <span>{w.content}</span>
                      {/* <button
                        className="btn-action-modul-session"
                        title="Supprimer"
                        onClick={() => onDeleteWord(w?.id)}
                      >
                        <FeatherIcon icon="trash-2" />
                      </button> */}
                    </div>
                  ))}
                </ul>
              </div>
            </React.Fragment>
          ))}
        </div>
      </div>
    </div>
  )
}

export default SupportGlisseDepot
