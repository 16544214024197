import FeatherIcon from "feather-icons-react";
import React from "react";
import ModalEditScale from "../ModalEditScale";
import "./SupportContent.css";
import { useDeleteItem } from "./SupportQcm";

const SupportContentScale = ({ data, onReload }) => {
	// console.log("data scale", data);
	const deleteScale = useDeleteScale(onReload);
	return (
		<div className="scale-component">
			<ol>
				{data?.map((item) => (
					<li key={item?.id}>
						<div className="d-flex align-items-center gap-3">
							<div className="">
								<div className="d-flex align-items-center">
									<h5 className="h-theme-light m-0">
										Question:
									</h5>
									<h6 className="m-0">{item?.question}</h6>
								</div>
							</div>
							<div className="btn-action-container">
								{/* <button className="btn-action-modul-session">
									<FeatherIcon icon="edit" width="16px" />
								</button> */}
								<EditBtnScale scale={item} onReload={onReload} />
								{/* <button
									className="btn-action-modul-session"
									onClick={() => deleteScale(item?.id)}
								>
									<FeatherIcon icon="trash-2" width="16px" />
								</button> */}
							</div>
						</div>
					</li>
				))}
			</ol>
		</div>
	);
};
function useDeleteScale(onReload) {
	return useDeleteItem(
		onReload,
		"scale",
		"Element  supprimé avec succés",
		"Element non supprimé"
	);
}

const EditBtnScale = ({ scale, onReload }) => {
	return (
		<>
			<button
				className="btn-action-modul-session"
				data-toggle="modal"
				data-target={`#editScaleModal${scale?.id}`}
			>
				<FeatherIcon icon="edit" width="16px" />
			</button>

			<div
				className="modal fade"
				id={`editScaleModal${scale?.id}`}
				tabIndex="-1"
				role="dialog"
				aria-labelledby="myLargeModalLabel"
				aria-hidden="true"
			>
				<div className="modal-dialog modal-xl">
					<div className="modal-content card-dashboard">
						<ModalEditScale scale={scale} onReload={onReload} />
					</div>
				</div>
			</div>
		</>
	);
};
export default SupportContentScale;
